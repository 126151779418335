import React, {Component} from 'react';
import ImageToText from "../Components/ImageToText";
import MenuBar from "../Components/MenuBar";

class ImageToTextPage extends Component {
    render() {
        return (
            <div>
                <MenuBar/>
                <ImageToText/>
            </div>
        );
    }
}

export default ImageToTextPage;