import React, {Component} from 'react';
import ImageToTextPage from "./Pages/ImageToTextPage";


class App extends Component {
    render() {
        return (
            <div>
              <ImageToTextPage/>
            </div>
        );
    }
}

export default App;